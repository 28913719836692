<template>
    <div
        class="giga-input_field giga-password_field full-field"
        :class="{
        'field-hasError': $v.password.$anyDirty && $v.password.$invalid,
        }"
    >
        <label class="input-field_label">Mot de passe</label>

        <div class="input-field_area">

            <input
                :type="fieldType"
                :id="id"
                :name="name"
                :placeholder="placeholder"
                v-model.trim="name"
                v-model="$v.password.$model"
                @input="$emit('input', $event.target.value)"
            />

            <button class="togglePassword" type="button" @click="switchPassword">
                <svg v-if="fieldType == 'password'" data-v-9ec8ce46="" width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-9ec8ce46="" fill-rule="evenodd" clip-rule="evenodd" d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z" fill="#132D48"></path></svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path d="M23.821,11.181v0a15.736,15.736,0,0,0-4.145-5.44l3.032-3.032L21.293,1.293,18,4.583A11.783,11.783,0,0,0,12,3C4.5,3,1.057,9.261.179,11.181a1.969,1.969,0,0,0,0,1.64,15.736,15.736,0,0,0,4.145,5.44L1.293,21.293l1.414,1.414L6,19.417A11.783,11.783,0,0,0,12,21c7.5,0,10.943-6.261,11.821-8.181A1.968,1.968,0,0,0,23.821,11.181ZM6,12a5.99,5.99,0,0,1,9.471-4.885L14.019,8.567A3.947,3.947,0,0,0,12,8a4,4,0,0,0-4,4,3.947,3.947,0,0,0,.567,2.019L7.115,15.471A5.961,5.961,0,0,1,6,12Zm6,6a5.961,5.961,0,0,1-3.471-1.115l1.452-1.452A3.947,3.947,0,0,0,12,16a4,4,0,0,0,4-4,3.947,3.947,0,0,0-.567-2.019l1.452-1.452A5.99,5.99,0,0,1,12,18Z"/></svg>
            </button>

        </div> 

        <div class="giga-login_errors" v-if="$v.password.$anyDirty && $v.password.$invalid">
            <ul class="login-errors_list">
                <li class="error-item">{{ $t("password_required") }}</li>
            </ul>
        </div>
    </div>
</template>

<script>

import { required } from "vuelidate/lib/validators";

export default {
    name: 'passwordField',
    props:{
        id:{
            type: String
        },
        name:{
            type: String
        },
        placeholder:{
            type: String
        }
    },
    data(){
        return{
            password: '',
            fieldType: 'password'
        }
    },
    validations: {
        password: {
            required,
        },
    },
    methods:{
        switchPassword(){
            this.fieldType = this.fieldType === "password" ? "text" : "password";
        },

    }
}
</script>